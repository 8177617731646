import api from '@apiInstance';

export default {
  add (category) {
    return api.post('category', category);
  },
  get (id) {
    return api.fetch('category/' + id);
  },
  list (params) {
    return api.fetch('/categories', { params });
  },
  update (category) {
    return api.patch('category', category);
  },
  delete (id) {
    return api.remove('category', id);
  }
};
