import api from '@apiInstance';

export default {
  add (body) {
    return api.post('brands', body);
  },
  get (id) {
    return api.fetch('brands/' + id);
  },
  list (params) {
    return api.fetch('/brands', { params });
  },
  update (body) {
    return api.patch('brands', body);
  },
  delete (id) {
    return api.remove('brands', id);
  }
};
