<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <DxDataGrid
          key-expr="id"
          :data-source="dataSource"
          :focused-row-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          @row-removed="handleEvent('row-removed', $event)"
        >
          <DxEditing
            :allow-updating="!isDisabled"
            :allow-deleting="!isDisabled"
            :allow-adding="!isDisabled"
            :use-icons="!isDisabled"
            mode="row"
          />

          <DxColumn v-if="!isDisabled" type="buttons">
            <DxButton icon="edit" :onClick="editItem" />
            <DxButton name="delete" />
            <DxButton
              hint="Set to default"
              icon="check"
              :onClick="setToDefault"
            />
          </DxColumn>

          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxSorting mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="false" />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel location="before" :visible="true" />

          <DxColumn
            :width="70"
            :allow-sorting="false"
            :allow-filtering="false"
            data-field="is_default"
            caption="Default"
          />
          <DxColumn
            :width="100"
            :allow-sorting="false"
            :allow-filtering="false"
            data-field="thumbnail"
            caption="Preview"
            cell-template="previewThumbnailTemplate"
          />
          <DxColumn data-field="name" caption="Name" />
          <DxColumn data-field="description" caption="Description" />

          <DxToolbar>
            <DxItem location="before" name="columnChooserButton" />
            <DxItem location="before" name="searchPanel" />
            <DxItem name="groupPanel" />

            <DxItem v-if="!isDisabled" template="addButtonTemplate" />
          </DxToolbar>

          <template #addButtonTemplate>
            <div>
              <b-button
                variant="primary"
                class="text-capitalize"
                @click="addModal = true"
              >
                Add
              </b-button>
            </div>
          </template>

          <template #previewThumbnailTemplate="{ data }">
            <div class="text-center">
              <b-avatar rounded="sm" size="3rem" :src="onViewThumbnail(data)" />
            </div>
          </template>
        </DxDataGrid>
      </b-col>
    </b-row>

    <b-modal v-model="addModal" centered @hide="closeModal()" size="lg">
      <template #modal-title>
        <b-form-checkbox
          @change="isDefault($event)"
          v-model="formData.is_default"
          checked="true"
          name="check-button"
          switch
          inline
        >
          Default Preview Image
        </b-form-checkbox>
      </template>

      <b-row>
        <b-col cols="12" md="6" v-if="imagePreview">
          <b-form-group label="Preview">
            <b-img center thumbnail fluid :src="imagePreview" />
          </b-form-group>
        </b-col>

        <b-col cols="12" :md="imagePreview ? '6' : '12'">
          <b-row>
            <b-col>
              <b-form-group label="Image">
                <b-form-file
                  accept="image/*"
                  v-model="imageUpload"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Name" label-for="v-name">
                <b-form-input v-model="formData.name"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Description" label-for="v-description">
                <b-form-textarea
                  v-model="formData.description"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="closeModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showErrorModal" centered hide-header>
      <b-card-text class="text-center">
        <h3>Are you sure?</h3>
        Default preview image is existing, you want to change it?
      </b-card-text>

      <template #modal-footer="{ close }">
        <div>
          <b-button
            variant="danger"
            class="bg-darken-4 mr-1"
            size="sm"
            @click="isCancelledSaving(close)"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="bg-darken-4"
            size="sm"
            @click="acceptDefault(close)"
          >
            Accept
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: 'ProductImagesComponent',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dataIndex: -1,
    addModal: false,
    showErrorModal: false,

    imageUpload: null,
    imagePreview: null,
    previousIndex: -1,

    formData: {
      id: 0,
      name: null,
      description: null,
      thumbnail: null,
      base64: null,
      url: null,
      is_default: false,
    },
  }),
  computed: {
    //
  },
  watch: {
    dataSource(v) {
      //
    },
    imageUpload(e) {
      if (e) {
        const reader = new window.FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          this.imagePreview = reader.result;

          this.formData.thumbnail = e.name;
          this.formData.name = e.name;
          this.formData.description = e.name;
          this.formData.base64 = reader.result;
        };
      }
    },
  },
  methods: {
    editItem(e) {
      if (e) {
        this.addModal = true;
        this.$nextTick(() => {
          const data = e.row.data;

          this.dataIndex = e.row.dataIndex;

          if (data.base64 !== null) {
            this.imagePreview = data.base64;
          } else {
            this.imagePreview = data.url;
          }

          this.formData = Object.assign({}, data);
        });
      }
    },
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        name: null,
        description: null,
        thumbnail: null,
        base64: null,
        url: null,
        is_default: false,
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.imagePreview = null;
        this.formData = Object.assign({}, defaultForm);
      });
    },
    onSubmit() {
      if (!this.formData.id) {
        this.formData.id = this.dataSource.length + 1;
      }

      if (this.dataIndex > -1) {
        this.dataSource.splice(this.dataIndex, 1);
        this.dataSource.splice(this.dataIndex, 0, this.formData);
      } else {
        this.dataSource.push(this.formData);
      }

      this.closeModal();
    },
    onViewThumbnail(event) {
      if (event.data.base64) {
        return event.data.base64;
      }

      const params = {
        model: 'products',
        id: event.row.key,
        thumbnail: event.row.data.thumbnail,
      };

      return this.getImage(params);
    },
    handleEvent(event) {
      //
    },
    setToDefault(event) {
      this.$emit('set-default', event);
    },
    isCancelledSaving(close) {
      close();

      this.formData.is_default = false;

      if (this.previousIndex > -1) {
        this.dataSource[this.previousIndex].is_default = true;
      }
    },
    acceptDefault(close) {
      close();

      const dataByIsDefault = this.dataSource.find((x) => {
        return x.is_default;
      });

      this.previousIndex = this.dataSource.indexOf(dataByIsDefault);

      for (const data of this.dataSource) {
        data.is_default = false;
      }
    },
    isDefault(event) {
      if (event) {
        const dataByIsDefault = this.dataSource.find((x) => {
          return x.is_default;
        });

        if (this.dataIndex > -1) {
          const dataByIndex = this.dataSource[this.dataIndex];

          if (dataByIsDefault) {
            this.showErrorModal = true;
            return dataByIsDefault && !(dataByIsDefault.id === dataByIndex.id);
          }
        }

        console.log(dataByIsDefault);

        if (dataByIsDefault) {
          this.showErrorModal = true;
        }
      }
    },
  },
};
</script>

<style></style>
