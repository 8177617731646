import api from '@apiInstance';

export default {
  add (manufacturer) {
    return api.post('manufacturer', manufacturer);
  },
  get (id) {
    return api.fetch('manufacturer/' + id);
  },
  list (params) {
    return api.fetch('/manufacturers', { params });
  },
  update (manufacturer) {
    return api.patch('manufacturer', manufacturer);
  },
  delete (id) {
    return api.remove('manufacturer', id);
  }
};
